@import "generic/all";
@import "helpers/all";
@import "utils/all";
@import "src/app/sass/utils/all";

@import '_angular-material-theme.scss';

@import './variables/css-variables';

:root {
  // we don't use secondary in webapp, it allows to have the good color for settings components
  --secondary: var(--primary);
  --modal-radius: 12px;
}

// Special case for webapp in dark mode
[data-theme=dark] {
  --grey-0: var(--grey-5);
  --grey-10-50: rgba(0, 0, 0, 0.5);
}

html, body {
  height: 100%;
  scroll-behavior: smooth;

  color: var(--black-grey);
}

b {
  font-family:  var(--font-family-semiBold);
}
strong {
  font-family:  var(--font-family-semiBold);
  font-weight: normal;
}

h3 {
  font-size: 1.125rem;
  padding-bottom: 12px;
}

li {
  list-style: inside;
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--grey) transparent;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--grey);
  border-radius: 20px;
}

@keyframes spin {
  0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg)
  }

  to {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg)
  }
}

.is-loading {
  color: transparent!important;
  pointer-events: none;
  &:after {
    left: calc(50% - (1em / 2));
    top: calc(50% - (1em / 2));
    position: absolute !important;
    -webkit-animation: spin .5s linear infinite;
    animation: spin .5s linear infinite;
    border-radius: 290486px;
    border-color: #fff #fff transparent transparent;
    border-style: solid;
    border-width: 2px;
    content: "";
    display: block;
    height: 1em;
    position: relative;
    width: 1em;
  }

  &.blured-overlay {
    position: relative;
    &:before {
      content: '';
      position: absolute;
      left: 0px;
      top: 0px;
      z-index: 100;
      background-color: transparent;
      backdrop-filter: blur(3px);
      width: 100%;
      height: 100%;
    }

    &:after {
      z-index: 101;
      border-color: var(--primary) var(--primary) transparent transparent;
    }
  }

}

// Sometimes routing produces an ng-component wrapping our expected route component
ng-component {
  width: 100%;
  height: 100%;
}

body app-context-menu {
  .menu-list {
    background-color: var(--grey-0) !important;
  }
}

body app-settings-page {
  lay-panel-block {
    background-color: var(--grey-0) !important;
  }
}

body {
  mat-datepicker-content {
    box-shadow: 0 4px 16px 0 rgba(66, 75, 97, 0.1) !important;
    border: 1px solid var(--silver);
    color: var(--black-grey);

    background-color: var(--grey-0) !important;
  }
}

body {
  app-radio-button-group {
    background-color: var(--grey-0);
  }
}

router-outlet {
  display: contents;
}